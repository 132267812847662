<div class="columns">
    <div class="column text-left pl-4">
        {{'os_select.your_OS' | translate}}<br>
        <span (click)="openModal()" class="link">{{'os_select.not_correct' | translate}}</span>
    </div>
    <div class="column text-right">
        @switch (profile.deviceOs) {
        @case ("macos") {
        <img src="./assets/img/os_logo/mac.png" alt="" class="w-250">
        }
        @case ("ios") {
        <img src="./assets/img/os_logo/ios.png" alt="" class="w-250">
        }
        @case ("windows") {
        <img src="./assets/img/os_logo/windows.png" alt="" class="w-250">
        }
        @case ("android") {
        <img src="./assets/img/os_logo/android.png" alt="" class="w-250">
        }
        @default {
        <span class="font-source_extralight text-text-blue">{{'instructions.no' | translate}}</span>
        }
        }
    </div>
</div>
<div class="modal" [ngClass]="{'is-active': _isModalActive}">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{'os_select.change_OS' | translate}}</p>
            <button class="delete" aria-label="close" (click)="closeModal()"></button>
        </header>
        <section class="modal-card-body">
            <div class="fixed inset-0 flex items-center justify-center z-50 bg-slate-200">
                <div class="bg-white  rounded-lg p-8 w-[500px]">
                    <div class="grid grid-rows-4 gap-4">
                        <div>
                            <button class="items-center no-border" (click)="changeOS('macos')">
                                <img src="./assets/img/os_logo/mac.png" alt="Apple macOS" class="">
                            </button>
                        </div>
                        <div>
                            <button class="items-center no-border" (click)="changeOS('windows')">
                                <img src="./assets/img/os_logo/windows.png" alt="Windows" class="">
                            </button>
                        </div>
                        <div>
                            <button class="items-center no-border" (click)="changeOS('ios')">
                                <img src="./assets/img/os_logo/ios.png" alt="Apple iOS" class="">
                            </button>
                        </div>
                        <div>
                            <button class="items-center no-border" (click)="changeOS('android')">
                                <img src="./assets/img/os_logo/android.png" alt="Android" class="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>