import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpUtilsService } from './core/services/internal/http-utils.service';
import { LanguageService } from './core/services/internal/language.service';
import { TranslationService } from './core/services/internal/translation.service';
import { ApiService } from './core/services/network/api.service';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/app';
import 'firebase/auth';
import { AuthFirebaseService } from './core/services/internal/authfirebase.service';
import { LayoutModule } from './layout/layout.module';

firebase.initializeApp(environment.firebase.configuration);
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		TranslateModule.forRoot(),
		FormsModule,
		LayoutModule
	],
	providers: [
		TranslationService,
		LanguageService,
		HttpUtilsService,
		ApiService,
		AuthFirebaseService,
		provideHttpClient(withInterceptorsFromDi())
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
