@if (_deviceOs) {
	<section>
		<div class="flex flex-col gap-8">
			@switch (_deviceOs) {
				@case (_device.iOS) {
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">1.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.ios.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/ios/install_step1.png" alt="" style="width:250px">
						</div>
					</div>
                    <div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">2.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.ios.install_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/ios/install_step2.png" alt="" style="width:250px">
						</div>
					</div>
                    <div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">3.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.ios.install_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/ios/install_step3.png" alt="" style="width:250px">
						</div>
					</div>
                    <div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">4.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.ios.install_step4' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/ios/install_step4.png" alt="" style="width:250px">
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">5.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.ios.install_step5' | translate"></span>
							</div>
						</div>
						<!-- <div class="m-auto w-48">
							<img src="./assets/img/instructions/install/ios/install_step1.png" alt="" style="width:250px">
						</div> -->
					</div>
				}
				@case (_device.Android) {
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">1.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.android.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/android/install_step1.png" alt="" style="width:250px">
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">2.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.android.install_step2' | translate"></span>
							</div>
						</div>
						<!-- <div class="m-auto w-48">
							<img src="./assets/img/instructions/install/android/install_step1.png" alt="" style="width:250px">
						</div> -->
					</div>
				}
				@case (_device.MacOS) {
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">1.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.mac.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/mac/install_step1.png" alt="" style="width:250px">
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">2.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.mac.install_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/mac/install_step2.png" alt="" style="width:250px">
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">3.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.mac.install_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/mac/install_step3.png" alt="" style="width:250px">
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">4.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.mac.install_step4' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<!-- <img src="./assets/img/instructions/install/mac/install_step4.png" alt="" style="width:250px"> -->
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">5.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.mac.install_step5' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<!-- <img src="./assets/img/instructions/install/mac/install_step5.png" alt="" style="width:250px"> -->
						</div>
					</div>
				}
				@case (_device.Windows) {
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">1.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.windows.install_step1' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/windows/install_step1.png" alt="" style="width:250px">
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">2.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.windows.install_step2' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<img src="./assets/img/instructions/install/windows/install_step2.png" alt="" style="width:250px">
						</div>
					</div>
					<div class="box" style="border: 1px solid #cdcdcd; width: 80%; margin:0 auto; margin-top:30px;">
						<div class="is-flex is-align-items-center is-justify-content-start"  >
							<div>
								<span class="is-size-3 has-text-grey-lighter">3.</span>
							</div>
							<div class="">
								<span class="is-size-5 has-text-link" [innerHTML]="'install_instructions.windows.install_step3' | translate"></span>
							</div>
						</div>
						<div class="m-auto w-48">
							<!-- <img src="./assets/img/instructions/install/windows/install_step3.png" alt="" style="width:250px"> -->
						</div>
					</div>
				}
				@default {
					<!-- Card 1 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 1 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 1. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
					<!-- Card 2 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 2 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 2. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
					<!-- Card 3 -->
					<div class="bg-white border border-gray-300 shadow-md overflow-hidden">
						<div class="p-6">
							<h3 class="text-2xl font-semibold mb-2">Titolo Card 3 default</h3>
							<p class="text-gray-700">Descrizione dettagliata della card 3. Questa descrizione spiega il contenuto
								della card in modo chiaro e conciso.</p>
						</div>
					</div>
				}
			}
		</div>
	</section>
}