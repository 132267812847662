export const locale = {
	lang: 'it',
	data: {
		"welcome_message": "Questo portale di provisioning è per il programma live WBA OpenRoaming®.",
		"instruction_message": "Usa il pulsante qui sotto per scaricare e installare il profilo OpenRoaming sui dispositivi compatibili.",
		"device_support_message": "I profili OpenRoaming di Guglielmo sono stati testati e funzionano sulla maggior parte dei dispositivi (inclusi iOS, Android, macOS e Windows 10 e 11).",
		"contact_message": "Se hai domande, contatta",
		"terms_conditions": "Seleziona la casella per accettare i termini e le condizioni",
		"download_button": "Scarica e installa il profilo",
		"footer_message": "Cliccando su 'Scarica e installa il profilo' comprendi i passaggi sopra indicati e accetti",
		"toc_link": "Termini e Condizioni",
		"and": "e",
		"privacy_policy_link": "Informativa sulla privacy",
		"powered_by": "Offerto da",
		"accept_terms": "Per scaricare e installare il profilo devi accettare i termini e le condizioni.",
		"checkbox_text": `Seleziona la casella per accettare i <a href='https://wballiance.com/openroaming/toc/' target='_blank'>Termini e Condizioni</a> e <a href='https://wballiance.com/openroaming/privacy-policy/'>l'Informativa Privacy</a>`,
		"page_not_found": "Pagina non trovata",
		"login_google":"Accedi con Google",
		"login_apple":"Accedi con Apple",
		"social_login_text":"Usa le credenziali Google o Apple per procedere con il download!",
		"error_browser":`<span class="text-xl font-source_extralight text-text-blue">Gentile utente, <br>il browser che stai utilizzando non è supportato. <br>Per garantire un'esperienza ottimale, ti consigliamo di passare a </span>`,
		"error_browser_thanks":`Grazie!`,
		os_select:{
			your_OS:`Il tuo Sistema Operativo è:`,
			not_correct:`Non è corretto? Puoi cambiarlo da qui`,
			change_OS:`Cambia Sistema Operativo`,
		},
		install_instructions:{
			title:"Come installare il tuo profilo account Wi-Fi",
			ios:{
				install_step1:`Apri l'applicazione <span class='font-source_regular text-text-blue'>"Impostazioni"</span> e clicca su <span class='font-source_regular text-text-blue'>"Profilo Scaricato"</span>`,
				install_step2:`Clicca su <span class='font-source_regular text-text-blue'>"Installare"</span> nell'angolo in alto a destra ed inserisci il codice di sblocco del tuo dispositivo`,
				install_step3:`Clicca su <span class='font-source_regular text-text-blue'>"Installare"</span> nella finesta che appare in basso per confermare l'installazione`,
				install_step4:`Dopo aver installato il profilo del tuo account Wi-Fi, fai clic su <span class='font-source_regular text-text-blue'>"Fine"</span>. Vedrai quindi che il tuo profilo è stato installato correttamente nella sezione <span class='font-source_regular text-text-blue'>"VPN e gestione dispositivi"</span>`,
				install_step5:`Ora è possibile cercare il nome della rete Wi-Fi e accedere in sicurezza`,
			},
			android:{
				install_step1:`Clicca su <span class='font-source_regular text-text-blue'>"Fine"</span> nella finesta che appare in basso per confermare l'installazione`,
				install_step2:`Ora è possibile cercare il nome della rete Wi-Fi e accedere in sicurezza`,
			},
			mac:{
				install_step1:`Clicca sul file scaricato e apri l'applicazione <span class='font-source_regular text-text-blue'>"Impostazioni"</span> e clicca su <span class='font-source_regular text-text-blue'>"Privacy & Sicurezza"</span>`,
				install_step2:`Clicca su <span class='font-source_regular text-text-blue'>"Profilo"</span>`,
				install_step3:`Doppio click sul profilo e poi sul <span class='font-source_regular text-text-blue'>"Install"</span>`,
				install_step4:`Se richiesto, inserisci la tua password personale e clicca su <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5:`Ora è possibile cercare il nome della rete Wi-Fi e accedere in sicurezza`,
			},
			windows:{
				install_step1:`Clicca su <span class='font-source_regular text-text-blue'>Apri Impostazioni</span> nella finestra che appare`,
				install_step2:`Clicca su <span class='font-source_regular text-text-blue'>Aggiungi</span> nella finestra che appare`,
				install_step3:`Ora è possibile cercare il nome della rete Wi-Fi e accedere in sicurezza`,
			},
			icon:{
				watch:`Guarda il video`,
				listen:`Ascolta le istruzioni`,
				read:`Leggi le istruzioni`,
			}
		},
	},
}