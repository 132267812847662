export const locale = {
	lang: 'en',
	data: {
		"welcome_message": "This provisioning portal is for the WBA OpenRoaming® Live Program.",
		"instruction_message": "Use the button below to download and install the OpenRoaming profile on compatible devices.",
		"device_support_message": "Guglielmo's OpenRoaming profiles are proven to work on the majority of devices (including iOS, Android, macOS and Windows 10 & 11).",
		"contact_message": "If you encounter any questions, please contact",
		"terms_conditions": "Please check the box to accept terms and conditions",
		"download_button": "Download and install profile",
		"footer_message": "By clicking 'Download and install profile' you understand the above steps and accept our",
		"toc_link": "Terms & Conditions",
		"and": "and",
		"privacy_policy_link": "Privacy Policy",
		"powered_by": "Powered by",
		"accept_terms": "To download and install the profile you must accept the terms and conditions.",
		"checkbox_text": `Select the box to accept the <a href='https://wballiance.com/openroaming/toc/' target='_blank'>Terms and Conditions</a> and <a href='https://wballiance.com/openroaming/privacy-policy/'>Privacy Policy</a>`,
		"page_not_found": "Page not found",
		"login_google": "Login with Google",
		"login_apple": "Login with Apple",
		"social_login_text": "Use your Google or Apple credentials to proceed with the download!",
		"error_browser": `<span class="text-xl font-source_extralight text-text-blue">Dear user, <br>the browser you are using is not supported. <br>To ensure an optimal experience, we recommend switching to </span>`,
		"error_browser_thanks": `Thank you!`,
		os_select: {
			your_OS: `Your Operating System is:`,
			not_correct: `Is this not correct? You can change it from here.`,
			change_OS: `Change Operating System`,
		},
		install_instructions: {
			title: "How to install your Wi-Fi Account Profile",
			ios: {
				install_step1: `Open the <span class='font-source_regular text-text-blue'>"Settings"</span> app and click on <span class='font-source_regular text-text-blue'>"Downloaded Profile"</span>`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>"Install"</span> in the top-right corner and enter your device's passcode`,
				install_step3: `Click on <span class='font-source_regular text-text-blue'>"Install"</span> in the window that appears at the bottom to confirm the installation`,
				install_step4: `After installing your Wi-Fi account profile, click on <span class='font-source_regular text-text-blue'>"Done"</span>. You will see that your profile has been successfully installed in the <span class='font-source_regular text-text-blue'>"VPN & Device Management"</span> section`,
				install_step5: `It is now possible to search for the Wi-Fi network name and access it securely`,
			},
			android: {
				install_step1: `Click on <span class='font-source_regular text-text-blue'>"Done"</span> in the window that appears below to confirm the installation`,
				install_step2: `You can now search for the Wi-Fi network name and securely connect`,
			},
			mac: {
				install_step1: `Click on the downloaded file, open the <span class='font-source_regular text-text-blue'>"Settings"</span> app, and click on <span class='font-source_regular text-text-blue'>"Privacy & Security"</span>`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>"Profile"</span>`,
				install_step3: `Double click on the profile, then click on <span class='font-source_regular text-text-blue'>"Install"</span>`,
				install_step4: `If prompted, enter your personal password and click <span class='font-source_regular text-text-blue'>"OK"</span>`,
				install_step5: `You can now search for the Wi-Fi network name and securely connect`,
			},
			windows: {
				install_step1: `Click on <span class='font-source_regular text-text-blue'>Open Settings</span> in the window that appears`,
				install_step2: `Click on <span class='font-source_regular text-text-blue'>Add</span> in the window that appears`,
				install_step3: `You are now able to search for the Wi-Fi network name and connect securely`,
			},
			icon: {
				watch: `Watch the video`,
				listen: `Listen to the instructions`,
				read: `Read the instructions`,
			}
		},
	}
}