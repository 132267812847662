import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthFirebaseService } from '../services/internal/authfirebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private readonly loginPage = '/access'; // Percorso centralizzato per la pagina di login

  constructor(private authService: AuthFirebaseService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.alreadyLoggedin().pipe(
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate([this.loginPage]);
        }
      }),
      catchError(err => {
        // console.error('Errore durante la verifica dello stato di login:', err);
        this.router.navigate([this.loginPage]);
        return of(false); // Blocca l'accesso in caso di errore
      })
    );
  }
}
