import { JsonObject, JsonProperty } from "json2typescript";
import { Visit } from "./visit";

/**
 * Represents a profile visit.
 */
@JsonObject("Profile")
export class Profile extends Visit {
	/**
	 * The email associated with the profile.
	 */
	@JsonProperty("email", String, true)
	email?: string;


	/**
	 * Overrides the `toQS` method to generate a query string with additional parameters.
	 *
	 * @returns {string} The generated query string with optional parameters for `visitId`, `deviceOs`, and `email`.
	 * If `visitId`, `deviceOs`, or `email` are not defined, they will be omitted from the query string.
	 */
	override toQS() {
		const baseQueryString = super.toQS();
		return `${baseQueryString}${this.email ? `&email=${this.email}` : ''}`;
	}

	/**
	 * Converts the Visit object to a DTO (Data Transfer Object).
	 * @returns The DTO representation of the Profile object.
	 */
	override dto() {
		const request: any = super.dto();
		request.email = this.email;
		return request;
	}
}
