import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Device } from 'src/app/core/models/device.enum';
import { OSService } from 'src/app/core/services/internal/os.service';

@Component({
  selector: 'app-install-instructions',
  templateUrl: './install.component.html',
  styleUrl: './install.component.less'
})
export class InstallInstructionsComponent implements OnInit {
  _deviceOs = localStorage.getItem('OSSelected') || '';
  _device = Device;
  private _subscriptions: Array<Subscription> = [];
  constructor(
      private _OSService: OSService
  ) { }
  ngOnInit(): void {
      this.checkIfOSChengeInLS();
  }

  private checkIfOSChengeInLS(): void {
      this._subscriptions.push(this._OSService._myValueSubject.subscribe({
          next: (value) => {
              this._deviceOs = value;
          },
          error: (err) => {
              console.log(err);
          }
      }))
  }
  ngOnDestroy(): void {
      this._subscriptions.forEach(b => b.unsubscribe());
  }
}