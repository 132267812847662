import { Component } from '@angular/core';
import { Profile } from 'src/app/core/models/profile';

@Component({
  selector: 'app-os-select',
  templateUrl: './os-select.component.html',
  styleUrl: './os-select.component.less'
})
export class OsSelectComponent {
    // _modalVisible:boolean = false;
    _isModalActive:boolean = false;
    profile = new Profile();
    ngOnInit(): void {
        this.profile.deviceOs = localStorage.getItem('OSSelected') || '';
    }

    openModal(): void {
        this._isModalActive = true;
	}
    closeModal(): void {
		this._isModalActive = false;
	}
    changeOS(newOs: string): void {
		this.profile.deviceOs = newOs;
		localStorage.setItem('OSSelected', newOs);
		this.closeModal();
	}
}
