import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class OSService {
    _myValueSubject = new BehaviorSubject<string>('');
    private _lastValue: string | null = null;

    constructor() {
        this._lastValue = localStorage.getItem('OSSelected');
        this._myValueSubject.next(this._lastValue || '');

        setInterval(() => {
            const currentValue = localStorage.getItem('OSSelected');
            if (currentValue !== this._lastValue) {
                this._lastValue = currentValue;
                this._myValueSubject.next(currentValue || '');
            }
        }, 1000);
    }

    setMyValue(value: string) {
        localStorage.setItem('OSSelected', value);
        this._myValueSubject.next(value);
        this._lastValue = value;
    }
}