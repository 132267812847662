import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';

const routes: Routes = [
	{ path: 'page-not-found', loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
	{ path: 'access', loadChildren: () => import('./pages/access/access.module').then(m => m.AccessModule) },
	{ path: 'how-to-install',canActivate: [AuthGuard], loadChildren: () => import('./pages/how-to-install/how-to-install.module').then(m => m.HowToInstallModule) },
	{ path: '', canActivate: [AuthGuard], loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: 'page-not-found'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
