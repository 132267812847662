import { ChangeDetectorRef, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslationService } from './core/services/internal/translation.service';
import { Helpers } from './core/helpers';
import { locale as enLang } from './core/config/i18n/en';
import { locale as itLang } from './core/config/i18n/it';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent {

	constructor(
		private translationService: TranslationService,
		private chRef: ChangeDetectorRef
	) {
		console.info('%cVersion: %s', 'color: red; font-weight: bold;', environment.version);
	}

	ngAfterViewInit(): void {
		const userLang = Helpers.getLanguage() || navigator.language.substring(0, 2).toLowerCase();
		this.setLanguage(userLang);
		this.registerTranslations();
		this.chRef.detectChanges();
	}

	private setLanguage(lang: string): void {
		if (!Helpers.getLanguage()) {
			Helpers.setLanguage(lang);
		}
		this.translationService.setLanguage(lang);
	}

	private registerTranslations(): void {
		this.translationService.loadTranslations(
			enLang,
			itLang,
		);
	}
}
