import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable, mergeMap, map, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BooleanResponse } from '../../models/boolean-response';
import { Visit } from '../../models/visit';
import { HttpUtilsService } from '../internal/http-utils.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	private readonly baseUrl = environment.baseUrl;
	private readonly tenant_id = environment.tenant_id;
	private readonly service_id = environment.service_id;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	visit(request: Visit): Observable<boolean> {
		const body = JSON.stringify(request.dto());
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap((headers: HttpHeaders) => {
				return this.http.post<boolean>(`${this.baseUrl}/${this.tenant_id}/${this.service_id}/visit`, body, { headers }).pipe(
					map((res: any) => {
						const jsonConvert = new JsonConvert();
						return jsonConvert.deserializeObject(res, BooleanResponse).data || false;
					}),
					catchError(err => {
						throw err;
					})
				);
			})
		);
	}
}