import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OsSelectComponent } from './os-select/os-select.component';
import { InstallInstructionsComponent } from './instruction/install/install.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        TranslateModule,
    ],
    declarations: [
        InstallInstructionsComponent,
        OsSelectComponent,
    ],
    exports: [
        InstallInstructionsComponent,
        OsSelectComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule { }