<div class="container">
	<div class="content">
		<div class="text-center column is-12">
			<img src="./assets/img/logo_openroaning.png" alt="" class="w-300">
			<div>
				<router-outlet></router-outlet>
			</div>
			<div>
				<p class="footer-text">
					<span class="footer-text powered">
						<span>{{'powered_by' | translate}}</span>&nbsp;&nbsp;
						<a href="https://guglielmo.biz" target="_blank">
							<img src="./assets/img/guglielmo_logo.png" alt="Guglielmo Logo" class="gug_logo">
						</a>
					</span>
				</p>
			</div>
		</div>
		<div class="lumen-footer-logo"><img src="./assets/img/logo.png" alt="Lumen Logo"></div>
	</div>
</div>