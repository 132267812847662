import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthFirebaseService {

	constructor(public router: Router) { }

	/** Chekc if user has a valid session */
	alreadyLoggedin(): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			firebase.auth().onAuthStateChanged(async (user) => {
				if (!user) {
					localStorage.removeItem('fu');
					observer.next(false);
					observer.complete();
					return;
				}
				try {
					localStorage.setItem('fu', JSON.stringify(user.toJSON()));
					const userLocal = JSON.parse(localStorage.getItem('fu') || '{}');
					const expire = moment(userLocal.stsTokenManager?.expirationTime);
					const now = moment();
					if (expire.isAfter(now)) {
						observer.next(true);
					} else {
						const sessionExpire = localStorage.getItem('session-expire')
							? moment.unix(Number(localStorage.getItem('session-expire')))
							: moment().subtract(10, 'years');
						if (sessionExpire.isAfter(now)) {
							await user.reload();
							const currentUser = firebase.auth().currentUser;
							if (currentUser) {
								localStorage.setItem('fu', JSON.stringify(currentUser.toJSON()));
								observer.next(true);
							} else {
								localStorage.removeItem('fu');
								observer.next(false);
							}
						} else {
							localStorage.removeItem('fu');
							observer.next(false);
						}
					}
				} catch (error) {
					console.error('Errore durante la gestione dello stato di login:', error);
					localStorage.removeItem('fu');
					observer.next(false);
				}
				observer.complete();
			});
		});
	}


    async login(email: string, password: string) {
        return await firebase.auth().signInWithEmailAndPassword(email, password);
    }

    async loginWithGoogle() {
        return await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    async loginWithApple() {
		const provider = new firebase.auth.OAuthProvider('apple.com');
    	provider.addScope('email');
        return await firebase.auth().signInWithPopup(new firebase.auth.OAuthProvider('apple.com'));
    }

    async register(email: string, password: string/* , displayName: string */) {
        return await firebase.auth().createUserWithEmailAndPassword(email, password);
    }

    async sendEmailVerification() {
        return await firebase.auth().currentUser?.sendEmailVerification();
    }

    async sendPasswordResetEmail(passwordResetEmail: string) {
        return await firebase.auth().sendPasswordResetEmail(passwordResetEmail);
    }

	async logout() {
		return await firebase.auth().signOut();
	}

}
