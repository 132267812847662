// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	baseUrl: 'https://idp.guglielmo.biz/api',
	tenant_id: 'guglielmo',
	service_id: 'idp-guglielmo-biz',
    version: "2.2.1-DEV",
	firebase: {
        configuration: {
			apiKey: "AIzaSyDjUd6wzrmyyEzhYuPrY8PMYQeFI5UpEQQ",
			authDomain: "openroaming-a24ff.firebaseapp.com",
			projectId: "openroaming-a24ff",
			storageBucket: "openroaming-a24ff.firebasestorage.app",
			messagingSenderId: "1009544919661",
			appId: "1:1009544919661:web:669ceaacae1fc8eef6dd64",
			measurementId: "G-D4TCZ1NF3H"

        }
	}
}
