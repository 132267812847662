import { JsonObject, JsonProperty } from "json2typescript";
import { v4 } from "uuid";
import { Helpers } from "../helpers";

/**
 * Represents a Visit object.
 */
@JsonObject("Visit")
export class Visit {
	/**
	 * The device operating system.
	 */
	@JsonProperty("device_os", String)
	deviceOs: string = "";

	/**
	 * The unique identifier for the visit.
	 */
	@JsonProperty("visit_id", String)
	visitId: string = "";

	/**
	 * Sets the device operating system and visit ID information.
	 *
	 * @param os - A string representing the operating system.
	 * @param visitId - An optional string representing the visit ID.
	 *
	 * The function performs the following actions:
	 * 1. Calls `setDeviceOs` to set the device operating system based on the provided `os` string.
	 * 2. If a `visitId` is provided, it sets the `visitId` property to the provided value.
	 *    Otherwise, it generates a new visit ID using the `v4()` function and sets the `visitId` property to this new value.
	 * 3. Calls `Helpers.setVisitiIdInLocalStorage` to store the `visitId` in local storage.
	 */
	set(os: string, visitId?: string) {
		this.setDeviceOs(os);
		this.setVisitId(visitId);
	}

	/**
	 * Sets the visit ID to the provided value or generates a new visit ID.
	 *
	 * @param visitId - An optional string representing the visit ID.
	 *
	 * If a `visitId` is provided, it sets the `visitId` property to the provided value.
	 * Otherwise, it generates a new visit ID using the `v4()` function and sets the `visitId` property to this new value.
	 * It also calls `Helpers.setVisitiIdInLocalStorage` to store the `visitId` in local storage
	 * after setting the `visitId` property.
	 */
	setVisitId(visitId?: string) {
		if (visitId) {
			this.visitId = visitId;
		} else {
			this.visitId = v4();
		}
		Helpers.setVisitiIdInLocalStorage(this.visitId);
	}

	/**
	 * Sets the device operating system based on the provided string.
	 *
	 * @param os - A string representing the operating system.
	 *
	 * The function maps the provided operating system string to a corresponding
	 * device OS enum value. If the provided string matches one of the known OS
	 * names ("Mac OS X", "iOS", "Android", "Windows"), it sets the `deviceOs`
	 * property to the corresponding enum value from the `Device` enum.
	 * If the provided string does not match any known OS names, it sets the
	 * `deviceOs` property to `Device.UndefinedOS`.
	 */
	setDeviceOs(os: string) {
		this.deviceOs = Helpers.getDeviceOs(os);
	}

	/**
	 * Converts the Visit object to a query string representation.
	 * @returns The query string representation of the Visit object.
	 */
	toQS() {
		return `?${this.visitId ? `visit_id=${this.visitId}` : ''}&${this.deviceOs ? `device_os=${this.deviceOs}` : ''}`;
		// return `visit_id=${this.visitId}&device_os=${this.deviceOs}`;
	}

	/**
	 * Converts the Visit object to a DTO (Data Transfer Object).
	 * @returns The DTO representation of the Visit object.
	 */
	dto() {
		localStorage.setItem('OSSelected', this.deviceOs);
		return {
			device_os: this.deviceOs,
			visit_id: this.visitId
		}
	}
}